import axios from 'axios';
import { saveAccessToken } from '@/auth/utils'; // Import your utility functions for token handling

// Create an axios instance
const http = axios.create({
  baseURL: 'http://sandbox.purpletrac.io/backend',
  // You can set more defaults here
});

// Add a response interceptor
http.interceptors.response.use(
  response => response, // If the request succeeds, just return the response
  async error => {
    // If the request fails, check if it is because of a 401 Unauthorized response
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
          //const refreshToken = getRefreshToken(); // Get the refresh token from where you store it
          const response = await http.post('/jwt/refresh-token');
          
          const { accessToken } = response.data;
          saveAccessToken(accessToken); // Save the new access token wherever you store it
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          
          return http(originalRequest); // Retry the original request with the new token
        } catch (refreshError) {
          console.log("need to logout user so they can relog in");//redirectToLogin(); // If token refresh fails, redirect to login
        }
      }
    }

    // Return any error which is not due to authorization to the caller
    return Promise.reject(error);
  }
);

export default http;