<template>
    <b-card no-body>
      <b-card-header>
        <h3>Routes List</h3>
      </b-card-header>
  
      <b-card-body>
        <div class="d-flex">
          <b-button
            v-b-modal.modal-center
            class="mr-2"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="onAddRoute"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            Add Route
          </b-button>
        </div>
        <br />
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label-align-sm="left"
            label-cols-sm="2"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
          </b-form-group>
  
          <b-col md="3" sm="8" xs="6">
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
              class="mb-0"
            >
              <!-- <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            /> -->
              <b-input-group>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-card-body>
      <br />
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :current-page="currentPage"
        :items="history_list"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(action)="data">
          <b-button
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            title="Edit Route Data"
            @click="onEdit(data.item)"
            style="
              position: relative;
              padding: 8px;
              width: 31px;
              margin-top: 0px !important;
            "
            v-b-modal.modal-center
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              title="Edit Route Data"
            />
          </b-button>
        </template>
      </b-table>
  
      <!--  -->
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- pagination -->
        <div>
          <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination> -->
        </div>
      </b-card-body>
  
      <b-modal
      size="lg"
      id="modal-add-route"
      centered
      ok-only
      ok-title="Cancel"
      ref="modal-add-route"
      title="Add Route"
    >
      <b-row class="match-height">
        <b-col cols="12">
          <b-form-group
            label="Domicile"
            label-for="h-domicile-name"
            label-cols-md="4"
          >
          <v-select
            label="label"
            placeholder="Select"
            v-model="domicileName"
            :options="domicileOptions"
          />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Route"
            label-for="h-route-name"
            label-cols-md="4"
          >
            <b-form-input id="h-route-name" v-model="routeName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Origin"
            label-for="h-origin-name"
            label-cols-md="4"
          >
            <b-form-input id="h-origin-name" v-model="originName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Destination"
            label-for="h-destination-name"
            label-cols-md="4"
          >
            <b-form-input id="h-destination-name" v-model="destinationName" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Distance"
            label-for="h-distance"
            label-cols-md="4"
          >
            <b-form-input id="h-distance-name" v-model="distance" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="h-status-name"
            label-cols-md="4"
          >
          <v-select
            label="label"
            placeholder="Select"
            v-model="statusName"
            :options="statusOptions"
          />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Start Timing Slots -->

      <b-row>
        <b-col cols="12">
          <b-button @click="onSave" v-if="!this.id"
            >Submit
          </b-button>
          <b-button @click="onSave" v-if="this.id"
            >Update
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    </b-card>
  </template>
              
  <script>
  import {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    BDropdownItem,
    BDropdown,
    BLink,
    BPagination,
    BFormSelect,
  } from "bootstrap-vue";
  import { required, min, confirmed } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { serverUri } from "@/config";
  import router from "@/router";
  import { getUserData } from "@/auth/utils";
  import flatPickr from "vue-flatpickr-component";
  import moment from "moment";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import vSelect from "vue-select";
  import axios from "axios";
  import http from "@/services/http";
  
  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BFormCheckbox,
      BFormCheckboxGroup,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      BInputGroup,
      BInputGroupAppend,
      BFormTimepicker,
      BFormDatepicker,
      flatPickr,
      DateRangePicker,
      vSelect,
      BDropdownItem,
      BDropdown,
      BLink,
      BPagination,
      BFormSelect,
    },
    data() {
      return {
        checkbox: false,
        userId: getUserData().driver_id,
        companyId: getUserData().company_id,
        id:'',
        domicileName:'',
        routeName:'',
        originName:'',
        destinationName:'',
        statusName:'',
        distance:'',
        perPage: 25,
        pageOptions: [5, 10, 15, 25],
        option: [],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        history_list: [],
        domicileOptions:[],
        statusOptions:[{label:'Active',title:1},{label:'In-Active',title:0}],
        filterOn: [],
        totalRows: 1,
        currentPage: 1,
        filter: " ",
        fields: [
          {
            key: "domicile_id",
            label: "Domicile",
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "route",
            label: "Route",
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "origin",
            label: "Origin",
            sortable: true,
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "destination",
            label: "Destination",
            sortable: true,
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "distance",
            label: "Distance",
            sortable: true,
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
            formatter: (value) => {
              return value == 1 ? 'Active' : 'In-Active';
            },
          },
          {
            key: "action",
          },
        ],
      };
    },
    mounted() {},
    computed: {},
    props: {},
    methods: {
      onEdit(details) {
        this.id = details.id;
        this.domicileName = details.domicile_id;
        this.routeName = details.route;
        this.originName = details.origin;
        this.destinationName = details.destination;
        this.distance = details.distance;
        this.statusName = details.status == 1 ? 'Active' : 'In-Active';
  
        this.$refs["modal-add-route"].show();
      },
  
      getRoutesData() {
        http.get(`${serverUri}/utilities/allRoutesData`, 
          {
            headers: {
              'Authorization': `Bearer ${getUserData().accessToken}`,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
          this.history_list = res.data;
        });
      },
  
      onSave() {
        if (this.id) {
          axios
            .post(`${serverUri}/drivers/updateRouteByDomicile`, {
              route_id: this.id,
              domicile_id:this.domicileName.title == undefined ? this.domicileName : this.domicileName.title,
              company_id: this.companyId,
              route: this.routeName,
              origin: this.originName,
              destination: this.destinationName,
              distance: this.distance,
              status:this.statusName.title == undefined ? this.statusName : this.statusName.title,
            })
            .then((res) => {
              if (res.data.status) {
                this.showToast(
                  "success",
                  "Success",
                  res.data.message
                );
                this.$refs["modal-add-route"].hide();
  
                this.id = "";
                this.domicileName = "";
                this.routeName = "";
                this.originName = "";
                this.destinationName = "";
                this.distanceName = "";
                this.statusName = "";
                this.getRoutesData();

              } else {
                this.showToast("warning", "Failure", res.data.message);
              }
            });
        } else {

          axios
            .post(`${serverUri}/drivers/addRouteByDomicile`, {
              company_id: this.companyId,
              domicile_id:this.domicileName.title == undefined || this.domicileName.title == '' ? this.domicileName : this.domicileName.title,
              route: this.routeName,
              origin: this.originName,
              destination: this.destinationName,
              distance: this.distance,
              status:this.statusName.title == undefined || this.statusName.title == '' ? this.statusName : this.statusName.title,
            })
            .then((res) => {
              if (res.data.status) {
                this.showToast(
                  "success",
                  "Success",
                  res.data.message
                );
                this.$refs["modal-add-route"].hide();
  
                this.domicileName = "";
                this.routeName = "";
                this.originName = "";
                this.destinationName = "";
                this.distance = "";
                this.statusName = "";

                this.getRoutesData();
              } else {
                this.showToast("warning", "Failure", res.data.message);
              }
            });
        }
      },
  
      onAddRoute() {
        this.$refs["modal-add-route"].show();

        this.id = "";
        this.routeName = "";
        this.originName = "";
        this.destinationName = "";
        this.distance = "";
        this.statusName = "";
      },
  
      showToast(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: "BellIcon",
            text: text,
            variant,
          },
        });
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
    created() 
    {
      this.getRoutesData();
  
      // get domiciles
      axios
        .post(`${serverUri}/schedule/getdomicile`, {
          company: getUserData().company_id,
        })
        .then((res) => {
          const Response = res.data.filter((item) => item.domicile?.length > 0);
          Response.map((item) => {
            this.domicileOptions.push({
              title: item.domicile,
              label: item.domicile,
            });
          });
        });
    },
  };
  </script>
              
              <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  .edit-header {
    display: flex;
    align-items: center;
  }
  </style><style>
  .permission {
    margin: 10px 0;
  }
  
  .permission div {
    margin: 2px 0;
  }
  button.btn.btn-danger {
    margin-left: 10px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #f22323;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /* .dropdown {
    top: -20px;
  } */
  </style>
              